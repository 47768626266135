























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import FormCheckbox from '@/components/form/form-checkbox.vue';
import { unselectFirstElement,
  duplicateDataItems,
  duplicateDataItemsWithoutSelect,
  copyObject,
  downloadFile } from '@/helper/functions';
import apiBackend from '@/service/api-insurance';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import VersicherungsortRow from '@/views/components/elektronik-insurance/versicherungsort-row.vue';

const geltungsbereichItem = {
  isSelected: false,
  Strabe: '',
  PLZ: '',
  Ort: '',
  Land: '',
  ZursZone: '',
  Hausnummer: '',
  Starkregenzone: '',
  Naturgefahren2: '',
  Naturgefahren3: '',
  num: 0,
  geltungsbereich: '',
  individualAgreements: '',
};
const geltungsbereichType = 1;

type erstrisikosummenAdditionalItemType = {
  name: string | null;
  ERS: string | null;
  geanderteERS: string | null;
};

const defaultOptionValue = 'Nein';

const erstrisikosummenItem = {
  name: '',
  ERS: '',
  geanderteERS: '',
};
const erstrisikosummenType = 2;

export default Vue.extend({
  name: 'elektronik-insurance-ELEKPauschal',
  mixins: [dataChangeMixin],
  components: {
    FormCheckbox,
    VersicherungsortRow,
  },
  data() {
    return {
      name: 'elektronik-insurance-ELEKPauschal',
      key: 'ELEKTRONIK_INSURANCE',
      submitedForm: false,
      geltungsbereichItems: [
        {
          ...geltungsbereichItem,
        },
      ],
      byGeltungsbereichType: geltungsbereichType,
      mitversicherteGerategruppen: {
        Gesamtversicherungssumme: {
          name: 'Gesamtversicherungssumme',
          versicherungssumme: 0,
          bewertungsjahr: null,
        },
        Mitversicherte: {
          name: 'Mitversicherte Gerätegruppen',
          versicherungssumme: 0,
          bewertungsjahr: null,
        },
        Gruppe1: {
          name: 'Gruppe 1: Büro-/ Kommunikationstechnik',
          versicherungssumme: null,
          bewertungsjahr: null,
          isSelected: true,
        },
        Gruppe2: {
          name: 'Gruppe 2: Mess- und Prüftechnik ohne Endoskopie',
          versicherungssumme: null,
          bewertungsjahr: null,
          isSelected: false,
        },
        Gruppe2Mit: {
          name: 'Gruppe 2: Mess- und Prüftechnik mit Endoskopie',
          versicherungssumme: null,
          bewertungsjahr: null,
          isSelected: false,
        },
        Gruppe3: {
          name: 'Gruppe 3: Satz- und Reprotechnik',
          versicherungssumme: null,
          bewertungsjahr: null,
          isSelected: false,
        },
        Gruppe4: {
          name: 'Gruppe 4: Bild- und Tontechnik',
          versicherungssumme: null,
          bewertungsjahr: null,
          isSelected: false,
        },
        Gruppe5: {
          name: 'Gruppe 5: Medizintechnik ohne Endoskopie',
          versicherungssumme: null,
          bewertungsjahr: null,
          isSelected: false,
        },
        Gruppe5Mit: {
          name: 'Gruppe 5: Medizintechnik mit Endoskopie',
          versicherungssumme: null,
          bewertungsjahr: null,
          isSelected: false,
        },
        Gruppe6: {
          name: 'Gruppe 6: Besondere Anlagen und Geräte',
          versicherungssumme: null,
          bewertungsjahr: null,
          isSelected: false,
        },
        aubendeckung: 0,
        bemerkungenHardware: '',
        beschreibungSonderrisikoGruppe6: '',
        beitragssatz: '',
      },
      selbstbeteiligungHardware: {
        tarif: {
          name: 'Tarif - SB',
          SB: null,
          SBPercentage: '',
          SBMin: '',
          SBMax: '',
        },
        variant1: {
          name: 'SB-Variante 1',
          SB: null,
          SBPercentage: '',
          SBMin: '',
          SBMax: '',
        },
        variant2: {
          name: 'SB-Variante 2',
          SB: null,
          SBPercentage: '',
          SBMin: '',
          SBMax: '',
        },
        variant3: {
          name: 'SB-Variante 3',
          SB: null,
          SBPercentage: '',
          SBMin: '',
          SBMax: '',
        },
      },
      software: {
        versicherungssumme: 0,
        aubendeckung: '',
        lagerungSicherheitsdatentrager: '',
        bemerkungenSoftware: '',
      },
      selbstbeteiligungSoftware: {
        tarif: {
          SB: null,
          SBPercentage: '',
          SBMin: '',
          SBMax: '',
        },
      },
      mehrkostenErtragsausfall: {
        selectValue: null,
        versicherungssumme: 0,
        aubendeckung: 0,
        bemerkungenMehrkostenErtragsausfall: '',
      },
      selbstbeteiligungHaftzeitMehrkosten: {
        tarif: {
          name: 'Tarif - SB',
          SB: 2,
          haftzeit: 12,
        },
      },
      deckungsanpassungen: {
        items: {
          ausschlussFeuerGemab: {
            name: 'Ausschluss Feuer gemäß (Nr. 1.2.)',
            value: defaultOptionValue,
          },
          ausschlussEinbruchdiebstahlGemab: {
            name: 'Ausschluss Einbruchdiebstahl gemäß (Nr. 1.2.)',
            value: defaultOptionValue,
          },
          ausschlussDiebstahlGemab: {
            name: 'Ausschluss Abhandenkommen gemäß (Nr. 1.2.)',
            value: defaultOptionValue,
          },
          ausschlussLeitungswasserGemab: {
            name: 'Ausschluss Leitungswasser gemäß (Nr. 1.2.)',
            value: defaultOptionValue,
          },
          ausschlussUberschwemmung: {
            name: 'Ausschluss Überschwemmung',
            value: defaultOptionValue,
          },
          ausschlussSturm: {
            name: 'Ausschluss Sturm',
            value: defaultOptionValue,
          },
        },
        einschlussErdbeben: defaultOptionValue,
        selbstbeteiligung: null,
        JHE: null,
      },
      erstrisikosummen: {
        items: {
          aufraumungsBergungsDekontaminationsUndEnsorgungskosten: {
            name: 'Aufräumungs-, Bergungs-, Dekontaminations- und Entsorgungskosten',
            ERS: null,
            geanderteERS: null,
          },
          dekontaminationsUndEntsorgungskostenFurErdreich: {
            name: 'Dekontaminations- und Entsorgungskosten für Erdreich',
            ERS: null,
            geanderteERS: null,
          },
          bewegungsUndSchutzkosten: {
            name: 'Bewegungs- und Schutzkosten',
            ERS: null,
            geanderteERS: null,
          },
          luftfrachtkosten: {
            name: 'Luftfrachtkosten',
            ERS: null,
            geanderteERS: null,
          },
          bergungskosten: {
            name: 'Bergungskosten',
            ERS: null,
            geanderteERS: null,
          },
          kostenFurErdPflasterMauerUndStemmarbeiten: {
            name: 'Kosten für Erd-, Pflaster-, Mauer- und Stemmarbeiten',
            ERS: null,
            geanderteERS: null,
          },
        },
        additionItems: [] as erstrisikosummenAdditionalItemType[],
      },
      byErstrisikosummenType: erstrisikosummenType,
      notizen: '',
      pdfStyles: false,
      refImportPlacesFileName: 'excelImportPlaces',
      loadedData: false,
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'id', 'productId', 'processSavingData'],
  watch: {
    'mitversicherteGerategruppen.Gesamtversicherungssumme.versicherungssumme': function () {
      this.mehrkostenErtragsausfall.versicherungssumme = this.mitversicherteGerategruppen.Gesamtversicherungssumme.versicherungssumme * 0.5;
    },
    'mitversicherteGerategruppen.aubendeckung': function () {
      this.mehrkostenErtragsausfall.aubendeckung = this.mitversicherteGerategruppen.aubendeckung;
    },
    processSavingData(value: boolean) {
      if (this.insuranceData && this.insuranceData.basicData && this.firstOpenTab) {
        this.fillVersicherungsortItemsFromGruppendaten(this.insuranceData.basicData.versicherungsort);
        this.loadedData = true;
      }
    },
  },
  methods: {
    uploadPlacesFile(element) {
      const file = element.target.files[0];

      if (file) {
        apiBackend.uploadFile(`${this.id}/import-places-excel`, 'import', file).then((response: any) => {
          this.importPlacesFromExcel(response.data);
          this.excelImportPlacesRef.value = null;
        }).catch((e) => {
          console.error(`Error - ${e}`);
        });
      }
    },
    downloadInsurancePlacesTemplate() {
      apiBackend.downloadInsurancePlaces().then((response) => {
        downloadFile(response.data, 'Einlesen von Versicherungsorten.xlsx');
      });
    },
    importPlacesFromExcel(dataItems: any[]) {
      const firstElementEmpty = this.geltungsbereichItems.length === 1 &&
        !this.geltungsbereichItems[0].Strabe &&
        !this.geltungsbereichItems[0].PLZ &&
        !this.geltungsbereichItems[0].Ort &&
        !this.geltungsbereichItems[0].Land;

      dataItems.forEach((item, i) => {
        const fillFirst = firstElementEmpty && i === 0;

        const newItem = fillFirst ? this.geltungsbereichItems[0] : copyObject(geltungsbereichItem);

        newItem.Strabe = item.street;
        newItem.PLZ = item.postCode;
        newItem.Ort = item.place;
        newItem.Land = item.land;

        if (!fillFirst) {
          this.geltungsbereichItems.push(newItem);
        }
      });
    },
    exportPlacesData() {
      const data = this.geltungsbereichItems.map((itm) => ({
        street: itm.Strabe,
        code: itm.PLZ,
        city: itm.Ort,
        land: itm.Land,
      }));

      apiBackend.exportPlacesData(data).then((response) => {
        downloadFile(response.data, 'Versicherungsort-ELEKPauschal.xlsx');
      });
    },
    chooseImportPlacesFile() {
      this.excelImportPlacesRef.click();
    },
    goToBackUrl() {
      if (this.prevTabItem) {
        this.$emit('go-back-tab', this.prevTabItem.path, 'insuranceForms', this.prevTabItem.key);
      } else {
        this.$emit('go-back-tab', 'elektronik-insurance', 'insuranceForms', 1);
      }
    },
    fillVersicherungsortItemsFromGruppendaten(versicherungsort: any) {
      this.geltungsbereichItems[0].Strabe = versicherungsort.Strabe;
      this.geltungsbereichItems[0].PLZ = versicherungsort.PLZ;
      this.geltungsbereichItems[0].Ort = versicherungsort.Ort;
      this.geltungsbereichItems[0].Hausnummer = versicherungsort.Hausnummer;
      this.geltungsbereichItems[0].ZursZone = versicherungsort.ZursZone;
      this.geltungsbereichItems[0].Starkregenzone = versicherungsort.Starkregenzone;
    },
    exportToPDF() {
      const filename = `Zusammenfassung-ELEK-Pauschal-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    deleteErstrisikosummenItem(key) {
      this.erstrisikosummen.additionItems.splice(key, 1);
    },
    dublicateRow(fillData: boolean, type: number) {
      switch (type) {
        case this.byGeltungsbereichType:
          duplicateDataItems(geltungsbereichItem, this.geltungsbereichItems, fillData);
          break;
        case this.byErstrisikosummenType:
          duplicateDataItemsWithoutSelect(erstrisikosummenItem, this.erstrisikosummen.additionItems);
          break;
        default:
          console.log('Wrong type');
      }
    },
    deleteRows(type: number) {
      let items;
      switch (type) {
        case this.byGeltungsbereichType:
          unselectFirstElement(this.geltungsbereichItems);

          items = this.geltungsbereichItems.filter((item) => !item.isSelected);
          this.geltungsbereichItems = items;
          break;
        default:
          console.log('Wrong type');
      }
    },
    setValues(insuranceData: any) {
      const calculationData = insuranceData;
      this.geltungsbereichItems = calculationData.geltungsbereichItems;
      this.mitversicherteGerategruppen = calculationData.mitversicherteGerategruppen;
      this.selbstbeteiligungHardware = calculationData.selbstbeteiligungHardware;
      this.software = calculationData.software;
      this.selbstbeteiligungSoftware = calculationData.selbstbeteiligungSoftware;
      this.mehrkostenErtragsausfall = calculationData.mehrkostenErtragsausfall;
      this.selbstbeteiligungHaftzeitMehrkosten = calculationData.selbstbeteiligungHaftzeitMehrkosten;
      this.deckungsanpassungen = calculationData.deckungsanpassungen;
      this.erstrisikosummen = calculationData.erstrisikosummen;
      this.notizen = calculationData.notizen;
    },
    onSubmit(goNextPage = true) {
      const data = {
        geltungsbereichItems: this.geltungsbereichItems,
        mitversicherteGerategruppen: this.mitversicherteGerategruppen,
        selbstbeteiligungHardware: this.selbstbeteiligungHardware,
        software: this.software,
        selbstbeteiligungSoftware: this.selbstbeteiligungSoftware,
        mehrkostenErtragsausfall: this.mehrkostenErtragsausfall,
        selbstbeteiligungHaftzeitMehrkosten: this.selbstbeteiligungHaftzeitMehrkosten,
        deckungsanpassungen: this.deckungsanpassungen,
        erstrisikosummen: this.erstrisikosummen,
        notizen: this.notizen,
      };
      if (goNextPage) {
        this.$emit('insurance-forms-data-change', data, 'ELEKPauschal', this.nextTabId);
      } else {
        this.$emit('insurance-forms-data-change', data, 'ELEKPauschal', false);
      }

      this.submitedForm = true;
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData && this.insuranceData.insuranceFormsData && this.insuranceData.insuranceFormsData.ELEKPauschal) {
      this.setValues(this.insuranceData.insuranceFormsData.ELEKPauschal);
    }
    (this as any).startWatcherActivated();
  },
  beforeDestroy() {
    if (!this.isPCUser && !this.submitedForm) {
      this.onSubmit(false);
    }
  },
  computed: {
    prevTabItem(): any {
      return this.$store.getters['tabsModule/getPrevTab']('insuranceForms', 4);
    },
    firstOpenTab(): boolean {
      // eslint-disable-next-line no-prototype-builtins
      return (this.insuranceData === null || this.insuranceData.insuranceFormsData === null || !this.insuranceData.insuranceFormsData.hasOwnProperty('ELEKPauschal')) && (!this.loadedData);
    },
    showPlusBtn(): boolean {
      return (this.erstrisikosummen.additionItems.length < 5);
    },
    showMinusBtn(): boolean {
      return (this.erstrisikosummen.additionItems.length > 1);
    },
    nextTabId() {
      const activeTabs = this.insuranceData.basicData.Produktauswahl;

      const result = 6;

      if (activeTabs.Praxispolice) {
        return 5;
      }

      return result;
    },
    excelImportPlacesRef(): any {
      return this.$refs[this.refImportPlacesFileName];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
});
